import { useEffect, useState } from 'react';
import { Platform, useWindowDimensions } from 'react-native';

const SCREEN_SIZE: { [key: string]: number } = {
  DESKTOP_TRUE: 1400, // TODO-FAN-7318 Replace this with 'DESKTOP: 1400' and verify/fix styling regressions https://huddleinc.atlassian.net/browse/FAN-7318
  DESKTOP: 768,
  TABLET: 768,
  MOBILE: 520
};

const MAC_DEVICES = ['iphone', 'ipad', 'mac'];
const LINUX_DEVICES = ['linux']; // Can be used for android device, please add more specific device if you want.

export const useMediaQuery = () => {
  const { width, height } = useWindowDimensions();
  const [deviceWidth, setDeviceWidth] = useState(width);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setDeviceWidth(width);
    setLoading(false);
  }, [width]);

  const isTrulyDesktop = deviceWidth > 0 && deviceWidth > SCREEN_SIZE.DESKTOP_TRUE; // TODO-FAN-7318 Delete this temporary variable https://huddleinc.atlassian.net/browse/FAN-7318
  const isDesktop = deviceWidth > 0 && deviceWidth > SCREEN_SIZE.DESKTOP;
  const isTablet = deviceWidth > 0 && deviceWidth > SCREEN_SIZE.MOBILE && deviceWidth <= SCREEN_SIZE.TABLET;
  const isMobile = deviceWidth > 0 && deviceWidth <= SCREEN_SIZE.MOBILE;
  const isWebApp = Platform.OS === 'web';
  const isNativeApp = Platform.OS === 'ios' || Platform.OS === 'android';
  const isAndroid = Platform.OS === 'android';
  const isIOS = Platform.OS === 'ios';
  const isMobileApp = isMobile || isNativeApp;

  return {
    width,
    height,
    isTrulyDesktop,
    isDesktop,
    isTablet,
    isMobile,
    isMobileApp,
    isWebApp,
    isAndroid,
    isIOS,
    isNativeApp,
    loading
  };
};

export const useWebDevices = () => {
  const { platform } = window?.navigator || {};
  let isMacDevice;
  let isLinuxDevice;
  if (platform) {
    const platformPrefix = platform.split(' ')[0].toLocaleLowerCase();
    isMacDevice = MAC_DEVICES.includes(platformPrefix);
    isLinuxDevice = LINUX_DEVICES.includes(platformPrefix);
  }
  return {
    isMacDevice,
    isLinuxDevice
  };
};

export function getDeviceIndex(deviceWidth: number) {
  if (deviceWidth < SCREEN_SIZE.MOBILE) return 0;
  if (deviceWidth < SCREEN_SIZE.TABLET) return 1;
  return 2;
}

export const useResponsiveValue = (data: any) => {
  const correctedValues = (function () {
    if (Array.isArray(data)) {
      if (data.length == 1) {
        return [data[0], data[0], data[0]];
      } else if (data.length === 2) {
        return [data[0], data[1], data[1]];
      } else return data;
    } else {
      return [data, data, data];
    }
  })();

  const { width } = useWindowDimensions();

  let deviceIndex = (function () {
    if (width < SCREEN_SIZE.MOBILE) return 0;
    if (width < SCREEN_SIZE.TABLET) return 1;
    return 2;
  })();

  const [value, setValue] = useState(correctedValues[deviceIndex]);

  useEffect(() => {
    deviceIndex = (function () {
      if (width < SCREEN_SIZE.MOBILE) return 0;
      if (width < SCREEN_SIZE.TABLET) return 1;
      return 2;
    })();
    setValue(correctedValues[getDeviceIndex(width)]);
  }, [width]);

  return value;
};
