import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { GetCurrentApplicationConfiguration, isEmpty } from '../utils';
import isEqual from 'lodash/isEqual';
import { School } from '@gf/cross-platform-lib/interfaces';
import { TheStore } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { FirebaseRef } from '../providers/Firebase/index';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { fetchSchool, updateUserInfo } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

const appConfig = GetCurrentApplicationConfiguration();
//TODO: btoa is not work in native. we can use lib or another way.
// const fanStorageKey = btoa('fanandauth'.split('').reverse().join(''));
const fanStorageKey = 'fanandauth'.split('').reverse().join('');

export class Fan {
  private static instance: Fan;

  email: string;
  favIDsToSchoolName: { [key: string]: string };
  firebaseUser?: any;
  schoolIDsToVisitDate: { [key: string]: Date };
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;

  public static getInstance(): Fan {
    if (!Fan.instance) {
      Fan.instance = new Fan();
    }
    return Fan.instance;
  }

  private constructor() {
    this.email = '';
    this.favIDsToSchoolName = {};
    this.schoolIDsToVisitDate = {};
    this.firstName = '';
    this.lastName = '';
  }

  get favorSchoolIds() {
    return Object.keys(this.favIDsToSchoolName);
  }

  async init() {
    return TheStore?.getItem(fanStorageKey).then((fanStorageData: string) => {
      if (fanStorageData !== null) {
        const fan = JSON.parse(fanStorageData) as Fan;
        this.email = fan.email;
        this.favIDsToSchoolName = fan.favIDsToSchoolName;
        this.firebaseUser = fan.firebaseUser;
        this.schoolIDsToVisitDate = fan.schoolIDsToVisitDate;
        this.phoneNumber = fan.phoneNumber;
        this.firstName = fan.firstName;
        this.lastName = fan.lastName;
      } else {
        this.email = '';
        this.favIDsToSchoolName = {};
        this.schoolIDsToVisitDate = {};
        this.firstName = '';
        this.lastName = '';
      }
      return this;
    });
  }

  async reset() {
    this.firebaseUser = undefined;
    this.favIDsToSchoolName = {};
    this.phoneNumber = undefined;
    this.email = '';
    await this.saveLocal();
  }

  async addFavoriteSchoolById(huddleId: string) {
    return new Promise(resolve => {
      fetchSchool(huddleId).then(({ data }) => {
        const school: School = data as School;
        if (school?.name && !this.favIDsToSchoolName[huddleId]) {
          this.favIDsToSchoolName = { ...this.favIDsToSchoolName, [huddleId]: school.name };
        }
        resolve(this.favIDsToSchoolName);
      });
    });
  }

  async toggleFavorite(huddleId: string, schoolName: string) {
    if (this.favIDsToSchoolName[huddleId]) {
      this.favIDsToSchoolName = omit(this.favIDsToSchoolName, huddleId);
    } else {
      this.favIDsToSchoolName = {
        ...this.favIDsToSchoolName,
        [huddleId]: schoolName
      };
    }
    await this.save();
  }

  async save() {
    await this.saveLocal();
    // Do we have an email address?
    const token = await FirebaseRef.current.getAuthToken();

    if (isEmpty(token)) {
      return;
    }
    const userBody = {
      email: this.email,
      status: 'ENABLED',
      favoriteAccountIds: Object.keys(this.favIDsToSchoolName),
      firstName: this.firstName?.trim(),
      lastName: this.lastName?.trim()
    };

    appConfig.api.users.getUrlForMethodAndId &&
      updateUserInfo(this.email, userBody)
        .then(response => {
          return response.data;
        })
        .catch(error =>
          recordError(error, {
            originatingFunction: 'Fan-save-updateUserInfo',
            customMessage:
              'Error updating user info during Fan.save(), after sign-in and fetching of additional details',
            data: { email: this.email, userBody, token },
            errorGroup: NEW_RELIC_ERROR_GROUPS.UserActions
          })
        );
  }

  async saveLocal() {
    // Do we have local storage?
    // Did the fan object change?
    const newFan = {
      ...this,
      firebaseUser: pick(this.firebaseUser, ['uid', 'email', 'metadata', 'createdAt'])
    };
    const storedFan = JSON.parse((await TheStore.getItem(fanStorageKey)) || '{}');
    if (storedFan && isEqual(storedFan, newFan)) {
      return;
    }
    await TheStore.setItem(fanStorageKey, JSON.stringify(newFan));
  }
}
