import React, { useEffect, useState } from 'react';
import { Cart, Fan, MobilePass, TicketRedemption, TicketsAdditionalInfo } from '@gf/cross-platform-lib/models';
import { SearchPageProvider } from './search';
import { useFirebase } from './Firebase';
import isEmpty from 'lodash/isEmpty';

interface BusinessProviderProps {
  children: React.ReactNode;
}

export async function resetAllInstances() {
  await Fan.getInstance().reset();
  await TicketRedemption.getInstance().emptyTicketRedemption();
  await MobilePass.getInstance().emptyPasses();
  await Cart.getInstance().emptyCart();
  await TicketsAdditionalInfo.getInstance().clearTicketsForms();
}

export const BusinessProvider = ({ children }: BusinessProviderProps) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const firebase = useFirebase();
  const { appAuth } = firebase;
  const { currentUser: currentFirebaseUser, signOut: firebaseAuthSignOut } = appAuth;
  useEffect(() => {
    Promise.all([
      Cart.getInstance().init(),
      Fan.getInstance().init(),
      TicketRedemption.getInstance().init(),
      TicketsAdditionalInfo.getInstance().init()
    ])
      .then(() => MobilePass.getInstance().init())
      .then(async () => {
        if (!currentFirebaseUser() && !isEmpty(Fan.getInstance().firebaseUser) && Cart.getInstance().isEmpty()) {
          return resetAllInstances();
        } else if (currentFirebaseUser() && !Fan.getInstance().firebaseUser) {
          return firebaseAuthSignOut();
        }
        return true;
      })
      .then(() => {
        setIsReady(true);
      });
  }, []);

  return <SearchPageProvider>{isReady && children}</SearchPageProvider>;
};
