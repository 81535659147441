export const isStringNotEmpty = (str: string) => {
  return typeof str !== 'undefined' && str.length > 0;
};

export const getPosition = (str: string, subString: string, index: number) => {
  return str.split(subString, index).join(subString).length;
};
export const insertString = (str: string, value: string, index: number) => {
  return str.substring(0, index) + value + str.substring(index);
};
