import { envConfig } from './env';
import { config } from './config';
import { getLocation } from '../url';

export const GetCurrentEnvironmentName = () => envConfig?.currentEnvironment;
export const GetCurrentApplicationConfiguration = () => config;

export const IsProduction = () => GetCurrentEnvironmentName() === 'production';

const APP_URLS = {
  origin: config.origin.url,
  baseUrl: config.api.baseUrl,
  events: config.api.events.baseUrl,
  eventsV1: config.api.eventsV1.baseUrl,
  fanGateway: config.api.fanGateway.baseUrl,
  encoded: config.api.encoded.baseUrl,
  orders: config.api.orders.baseUrl,
  orderservice: config.api.orderservice.baseUrl,
  ordersV2: config.api.ordersV2.baseUrl,
  users: config.api.users.baseUrl,
  seasonRenewalFans: config.api.seasonRenewalFansEncoded.baseUrl,
  seasons: config.api.seasons.baseUrl,
  sponsor: config.api.sponsor.baseUrl,
  sponsorV2: config.api.sponsorV2.baseUrl,
  encrypt: config.api.encrypt.baseUrl,
  seatsioReleaseSeats: `${config.api.baseUrl}/seatsio/releaseSeats`,
  seatsioHoldToken: `${config.api.baseUrl}/seatsio/holdToken`,
  pdfDownload: `${config.assets.pdf.downloadUrl}`,
  team: config.api.team.baseUrl
};

let safeOriginSet = new Set();
let missingUrls: string[] = [];

Object.entries(APP_URLS).forEach(([key, url]) => {
  const origin = typeof url === 'string' ? getLocation(url).origin : null;

  if (!origin) {
    return missingUrls.push(key);
  }
  safeOriginSet.add(origin);
});

if (missingUrls.length > 0) {
  const message = `Missing url configs for: ${missingUrls.join()} in Env: ${GetCurrentEnvironmentName()}`;
  try {
    alert(message);
  } catch (e) {
    console.log(message);
  }
}

export const safeOrigins = Array.from(safeOriginSet);
