import React from 'react';
import { View, SafeAreaView, StyleSheet } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';

interface LayoutProps {
  children: React.ReactNode;
}

export const RootLayout = ({ children }: LayoutProps) => {
  const { isMobileApp } = useMediaQuery();

  return (
    <>
      <SafeAreaView style={{ flex: 0, backgroundColor: 'rgb(50, 50, 50)' }} />
      <SafeAreaView style={{ backgroundColor: 'white', flex: 1 }}>
        <StatusBar style='light' backgroundColor='#323232' translucent={false} />
        <View style={mainContentStyle(isMobileApp)}>{children}</View>
      </SafeAreaView>
    </>
  );
};

const mainContentStyle = (isMobileApp: boolean) => {
  if (isMobileApp) {
    return {
      flex: 1
    };
  }
  return styles.fullMinHeight;
};

const styles = StyleSheet.create({
  fullMinHeight: {
    minHeight: '100%'
  }
});
