import React, { createContext, useContext, useEffect, useState } from 'react';
import { LocationObject, LocationPermissionResponse, PermissionStatus, PermissionResponse } from 'expo-location';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';
import { TheStore } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { isSafari } from '@gf/cross-platform-lib/utils';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { useRouter } from '@gf/cross-platform-lib/hooks';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

interface LocationType {
  location?: LocationObject;
  refresh: () => void;
  checkPermission: () => void;
  error: any;
  loading: boolean;
  permission?: PermissionResponse;
}

const LocationContext = createContext<LocationType>({
  location: undefined,
  refresh: () => {},
  checkPermission: () => {},
  error: '',
  loading: false,
  permission: {
    status: PermissionStatus.UNDETERMINED,
    expires: 0,
    granted: false,
    canAskAgain: true
  }
});

export const storeLocationPermissionCache = async (permission: {
  permission: LocationPermissionResponse;
  location: LocationObject | {};
  expires: dayjs.Dayjs;
}) => {
  return isSafari() ? TheStore.setItem('location_permission', permission || undefined) : undefined;
};

export const getLocationPermissionCache = async () => {
  if (isSafari()) {
    const storedLocation = await TheStore.getItem('location_permission');
    if (storedLocation === null || storedLocation === undefined) {
      return {};
    }
    const { expires, location = {}, permission } = storedLocation || {};
    const isExpired = dayjs().isAfter(dayjs(expires));

    return {
      expires,
      isExpired,
      location,
      permission
    };
  }
  return {};
};

const PAGES_EXCLUDED = [
  GOFAN_APP_PAGES['terms-of-use'].path,
  GOFAN_APP_PAGES['privacy-policy'].path,
  GOFAN_APP_PAGES.smsTerms.path
];

export const LocationProvider = ({ children }: { children: React.ReactNode }) => {
  const [location, setLocation] = useState<LocationObject>();
  const [error, setError] = useState('');
  const [permission, setPerms] = useState<PermissionResponse>();
  const [loading, setLoading] = useState(false);
  const { currentRoute } = useRouter();

  const requestForegroundPermissionsAsync = async () => {
    return import('expo-location').then(mod => mod.requestForegroundPermissionsAsync());
  };

  const getCurrentPositionAsync = async () => {
    return import('expo-location').then(mod => mod.getCurrentPositionAsync({}));
  };

  const checkPermission = async () => {
    const storedLocation = await getLocationPermissionCache();
    if (
      (permission && permission.granted) ||
      (!isEmpty(storedLocation) && !storedLocation?.isExpired) ||
      PAGES_EXCLUDED.includes(currentRoute)
    ) {
      return;
    }
    const permsResponse = await requestForegroundPermissionsAsync();
    setPerms(permsResponse);

    if (!permission?.granted) {
      setError('Permission to access location was denied');
    }

    //STORE LOCATION TO LOCAL STORAGE
    await storeLocationPermissionCache({
      permission: permsResponse,
      location: {},
      expires: storedLocation?.expires && !storedLocation?.isExpired ? storedLocation?.expires : dayjs().add(1, 'day')
    });
  };

  const refresh = async () => {
    try {
      setLoading(true);
      const storedLocation = await getLocationPermissionCache();
      if (
        storedLocation &&
        storedLocation?.permission?.granted &&
        !isEmpty(storedLocation.location) &&
        !storedLocation?.isExpired
      ) {
        return setLocation(storedLocation.location);
      }

      if ((permission && permission.granted) || (storedLocation && storedLocation?.permission?.granted)) {
        const location = await getCurrentPositionAsync();
        await storeLocationPermissionCache({
          permission: storedLocation.permission,
          location,
          expires:
            storedLocation?.expires && !storedLocation?.isExpired ? storedLocation?.expires : dayjs().add(1, 'day')
        });
        setLocation(location);
      }
    } catch (error: any) {
      setError(error);
      recordError(error, {
        originatingFunction: 'LocationProvider-refresh',
        customMessage: `Error updating user's location on web in response to permission changes. 
          Failed to retrieve user's current location using getCurrentPositionAsync. 
          Current permission status: ${permission?.status || 'N/A'}`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.GeoLocation,
        data: { location, permission }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (permission && permission.granted) {
      refresh();
    }
  }, [permission]);

  const locationContextValue = { location, refresh, error, loading, checkPermission, permission };

  return <LocationContext.Provider value={locationContextValue}>{children}</LocationContext.Provider>;
};

// @ts-ignore
export const useLocation = () => useContext(LocationContext);
